<template>
  <div class="personal-message-layout">
    <Breadcrumb>
      <BreadcrumbItem>个人中心</BreadcrumbItem>
      <BreadcrumbItem>我的消息</BreadcrumbItem>
    </Breadcrumb>
    <div class="wrapper">
      <Table :columns="columns" :data="messageTableData" @on-row-click="handleRowClick">
        <template slot-scope="{ row, index }" slot="createTime">
          <span>{{ row.createTime | formatTime('yyyy-MM-dd hh:mm:ss') }}</span>
        </template>
        <template slot-scope="{ row, index }" slot="status">
          <span v-if="row.status === 0">未读</span>
          <span v-else style="color: #78cf7d">已读</span>
        </template>
      </Table>
      <Page :total="total" size="small" style="margin-top: 20px" @on-change="handlePageChange" />
    </div>
    <Modal v-model="showMessageContent">
      <h3 slot="header">消息内容</h3>
      {{ messageContent }}
    </Modal>
  </div>
</template>

<script>
import UserAPI from '@/api/user'
import { mapState } from 'vuex'

export default {
  name: 'PersonalMessage',
  created() {
    this.requestData()
  },
  data() {
    return {
      columns: [
        { key: 'title', title: '标题' },
        { key: 'createTime', title: '时间', slot: 'createTime', width: 180 },
        { key: 'status', title: '状态', slot: 'status', width: 180 }
      ],
      currentPage: 1,
      total: 0,
      messageContent: '',
      showMessageContent: false,
      messageTableData: []
    }
  },
  methods: {
    async requestData() {
      const payload = {
        pageNumber: this.currentPage - 1,
        pageSize: 10,
        type: 0,
        userId: this.info.userId
      }
      const { res } = await UserAPI.getPersonalMessage(payload)
      this.messageTableData = res.data
      this.total = res.total
    },
    async markAsReaded(msgId) {
      try {
        await UserAPI.readMessage(msgId)
        this.requestData()
      } finally {
      }
    },
    handlePageChange(page) {
      this.currentPage = page
      this.requestData()
    },
    handleRowClick(row) {
      this.messageContent = row.message
      this.showMessageContent = true
      if (row.status === 0) {
        this.markAsReaded(row.messageId)
      }
    }
  },
  computed: {
    ...mapState('user', ['info'])
  }
}
</script>

<style lang="less" scoped>
@import '../../../theme/mixin';
.personal-message-layout {
  text-align: left;
  padding: 10px 20px;
}
.wrapper {
  .--mixin-shadow;
  background: white;
  margin-top: 20px;
  padding: 10px;
}
</style>
